import React, { Fragment, useContext, useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import commaNumber from "comma-number"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import ColorBoard from "../components/ColorBoard"
import BreakpointUp from "../components/Media/BreakpointUp"
import BreakpointDown from "../components/Media/BreakpointDown"
import LikeProductCarousel from "../components/LikeProductCarousel"
// import CustomBuildingQuote from "../components/CustomBuildingQuote"
import CallDesignBuilding from "../components/CallDesignBuilding"
import { Section, SectionTitle, BreadCrumb } from "../components/Section"
import {
  PhoneIcon,
  WidthIcon,
  LenghtIcon,
  HeightIcon,
  TickIcon,
  QuoteIcon,
} from "../components/Icons"
import PrimaryButton from "../components/Button/PrimaryButton"
import DefaultButton from "../components/Button/DefaultButton"
import BluePatternBg from "../images/bluepattern-bg.jpg"
import Badge from "../components/Badge"
import QuotePopupProduct from "../components/QuotePopupProduct"
import ProductReviewWidget from "../components/ProductReviewWidget"
import AddToCart from "../components/AddToCart"
import { Context } from "../components/context"

const SectionGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  /* align-items: end; */
  margin: 0 -15px;
  ${BreakpointUp.lg`  
      margin:0 -40px;
  `}
`
const SectionGridLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`  
        padding-right: 40px;
        padding-left: 40px; 
    `}
  ${BreakpointUp.lg`        
        flex: 0 0 55%;
        max-width: 55%;
    `}
`

const FigureImg = styled.figure`
  position: relative;
  margin-bottom: 20px;
  .gatsby-image-wrapper {
    z-index: 1;
  }
`
const SectionGridRight = styled.div`
  position: relative;
  width: 100%;
  background-color: #f2f4f9;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`  
        padding-right: 40px;
        padding-left: 40px; 
        background-color:transparent;
        flex: 0 0 45%;
        max-width: 45%;
    `}
`
const Product = styled.div``

const SkuNo = styled.div`
  color: #999;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 10px;

  span {
    font-weight: 500;
  }
`
const ProductTitle = styled.h1`
  margin: 0 0 10px;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  ${BreakpointUp.lg`
    font-size: 24px;
    line-height: 34px;
  `}
  a {
    color: #000;
    &:hover,
    &:focus {
      color: #0b619b;
    }
  }
`
const ProductBody = styled.div`
  position: relative;
  padding: 20px;
  @media (min-width: 992px) {
    padding: 0 20px;
  }
  @media (min-width: 1600px) {
    padding: 0 40px;
  }
`
const ProductItemInfo = styled.div`
  padding: 0 0 20px 0;
  + .product-item {
    padding: 20px 0;
    border-top: 1px solid #d3d3d3;
    &.btn-action {
      strong {
        display: block;
        text-align: center;
        margin-bottom: 15px;
      }
      ${BreakpointDown.sm`    
        padding:0;
        border-top:none;
        > strong {
          display:none
        } 
      `}
    }
  }
`

const ProductSize = styled.ul`
  display: flex;
  align-items: center;
  margin: 0 -15px;
`
const ProductItemSize = styled.li`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 15px;
  margin-bottom: 0;
  flex-direction: column;
  .icon {
    display: inline-flex;
    svg {
      width: 40px;
      ${BreakpointUp.sm`		
        width:auto;
      `}
    }
  }
  .text {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    small {
      color: #999;
      white-space: nowrap;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      ${BreakpointUp.sm`		
        font-size:14px;              
        line-height:24px
      `}
      + strong {
        margin-left: 5px;
      }
    }
    strong {
      display: block;
      color: #000;
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      ${BreakpointUp.sm`		
        font-size:20px;
        line-height: 30px;
      `}
    }
  }
`

const FinanceOption = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -15px;
`
const FinanceType = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
`
const FinanceInfo = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
`
const FinanceItem = styled.div`
  display: flex;
  align-items: center;
  color: #000;
  position: relative;
  padding: 0;
  font-weight: 500;
  .icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    border: 6px solid rgba(223, 227, 239, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    + .text {
      margin-left: 5px;
    }
    svg {
      fill: #fc0002;
    }
  }
`
const FinancePane = styled.div`
  .label {
    color: #999;
    font-weight: 500;
    margin: 0;
    font-size: 14px;
  }
`
const Price = styled.div`
  small {
    color: #999;
    font-size: 24px;
  }
  strong {
    font-size: 30px;
    color: #fc0002;
    font-weight: 700;
  }
`
const ListPayment = styled.ul`
  display: flex;
  align-items: center;
  margin: 0 -10px;
`
const ListItemPayment = styled.li`
  padding: 0 10px;
  margin-bottom: 0;
  strong {
    font-size: 16px;
    color: #000;
    line-height: 20px;
  }
  span {
    display: block;
    color: #999;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
`

const ButtonToolbar = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -5px;
  justify-content: center;
  ${BreakpointUp.lg`    
  justify-content: center;
  `}
  ${BreakpointUp.xl`    
  margin:0 -10px;
  `}
  ${BreakpointDown.sm`    
    justify-content: center;
  `}
  a, button {
    padding: 0 5px;
    ${BreakpointUp.xl`
      padding:0 10px;
    `}
  }
  .btn {
    padding: 5px 15px;
    font-size: 12px;
    font-weight: 500;
    ${BreakpointUp.sm`    
      padding:10px 20px;
      font-size:14px;
    `}
    ${BreakpointUp.lg`    
      padding: 12px 20px;
      font-size: 16px; 
      font-weight:700;
    `}
    ${BreakpointUp.xl`    
      padding: 16px 30px;
      font-size: 18px; 
    `}
    .icon {
      > svg {
        ${BreakpointDown.sm`    
          width:12px;
        `}
      }
    }
  }
  ${BreakpointDown.sm`    
    position: fixed;
    bottom:0;
    left:0;
    right:0;
    padding: 15px 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    background-color:#fff;
    z-index:3;
  `}
`

const ListBadgeWrap = styled.div`
  padding: 0;
  h2 {
    font-size: 24px;
    line-height: 34px;
    color: rgba(255, 255, 255, 0.25);
    font-weight: 900;
    @media (min-width: 768px) {
      font-size: 28px;
      line-height: 38px;
    }
    @media (min-width: 992px) {
      font-size: 32px;
      line-height: 42px;
    }
  }
`
const ListBadge = styled.div`
  margin: 0 -6px 15px -6px;
  .badge {
    cursor: default;
    &:hover {
      background-color: transparent;
      color: #fff;
    }
  }
`
const ProductFeatures = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  justify-content: space-between;
`
const ProductFeatureLeft = styled.div`
  position: relative;
  padding: 0 15px;
  width: 100%;
  ${BreakpointUp.lg`
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    `}
`

const ProductFeatureRight = styled.div`
  position: relative;
  padding: 0 15px;
  width: 100%;
  ${BreakpointUp.lg`
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
	`}
`
const ProductFeatureContent = styled.div`
  margin-top: 40px;
  ${BreakpointUp.lg`
        margin-top:80px;
    `}
  h2 {
    font-size: 20px;
    line-height: 30px;
    ${BreakpointUp.md`
            font-size: 24px;
            line-height: 34px;
        `}
    ${BreakpointUp.lg`
            font-size: 28px;
            line-height: 38px;
        `}
  }
  a{
    display:inline-block;
  }
`
const HelpText = styled.div`
  color: #999;
  line-height: 24px;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  padding: 10px 0;
  text-align: center;
`
const TableDefault = styled.div`
  background-color: #fff;
  font-size: 14px;
  line-height: 24px;
  color: #666;
  ${BreakpointUp.md`
        font-size:18px;
        line-height:30px;
    `}
`
const TableRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #dde4f0;
`
const TableCol = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  padding: 10px 2px;
  strong {
    color: #000;
    font-weight: 700;
  }
  span {
    position: relative;
    padding-left: 15px;
    &:before {
      display: inline-block;
      background-color: #fc0002;
      border-radius: 1px;
      content: "";
      width: 6px;
      height: 6px;
      transform: rotate(45deg);
      position: absolute;
      left: 0;
      top: 9px;
    }
  }
`

const CustomBreadCrumb = styled(BreadCrumb)`
  top: 0;
`

const AddCompareBtn = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: red;
  font-size: 20px;
`

const PlusIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: red;
  color: white;
  font-size: 20px;
`

const ProductPage = ({ data, location }) => {
  const [isVisibleQuote, setQuoteVisible] = useState(false)
  const { addProductToComparison } = useContext(Context)

  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }
  const productData = data.contentfulProduct
  const relatedProductsData = data.allContentfulProduct.edges
  const rto_36_price = (
    productData.price / data.site.siteMetadata.loanPrice.apr_36
  ).toFixed(2)
  const rto_48_price = (
    productData.price / data.site.siteMetadata.loanPrice.apr_48
  ).toFixed(2)
  const rto_60_price = (
    productData.price / data.site.siteMetadata.loanPrice.apr_60
  ).toFixed(2)

  return (
    <Fragment>
      <Section
        pt="180px"
        pb="30px"
        xpt="120px"
        xpb="30px"
        bgColor="#F2F4F9"
        bgBefore={`url(${BluePatternBg})`}
        bgBeforeWidth="55%"
        bgBeforeHeight="100%"
        bgBeforeRepeat="repeat"
        className="product-info"
      >
        <div className="container">
          <SectionGrid>
            <SectionGridLeft>
              <CustomBreadCrumb className="white">
                <Link to="/">Home / </Link>
                <Link to={productData.productCategory.url}>
                  {productData.productCategory.name} /
                </Link>
                <span>{productData.productName}</span>
              </CustomBreadCrumb>
              {productData.productImages && (
                <FigureImg>
                  <GatsbyImage
                    image={productData.productImages.gatsbyImageData}
                    alt={productData.productName}
                  />
                </FigureImg>
              )}
              <ListBadgeWrap>
                <div className="container">
                  <h2>Building can be used as:</h2>
                  <ListBadge>
                    {productData.productApplications.map((item, index) => {
                      return (
                        <span key={index} className="badge-primary">
                          <Badge badgeText={item.name} />
                        </span>
                      )
                    })}
                  </ListBadge>
                </div>
              </ListBadgeWrap>
            </SectionGridLeft>
            <SectionGridRight>
              <Product>
                <ProductBody>
                  <ProductItemInfo className="product-item">
                    <SkuNo>
                      <span>SKU No: </span> {productData.productSku}
                    </SkuNo>
                    <ProductTitle>{productData.productName}</ProductTitle>
                    <Price>
                      <small>Starting Price :</small>{" "}
                      <strong>${commaNumber(productData.price)}*</strong>
                    </Price>
                  </ProductItemInfo>

                  {/* <ProductItemInfo className="product-item">
                    <button
                      type="button"
                      onClick={() => addProductToComparison("detail", productData)}
                    >
                      <AddCompareBtn>
                        <PlusIcon>+</PlusIcon>
                        {` `}Compare
                      </AddCompareBtn>
                    </button>
                  </ProductItemInfo> */}

                  <ProductItemInfo className="product-item">
                    <ProductSize>
                      <ProductItemSize>
                        <span className="icon">
                          <WidthIcon />
                        </span>
                        <span className="text">
                          <small>Width</small>{" "}
                          <strong>{productData.width}</strong>
                        </span>
                      </ProductItemSize>

                      <ProductItemSize>
                        <span className="icon">
                          <LenghtIcon />
                        </span>
                        <span className="text">
                          <small>Length</small>
                          <strong>{productData.length}</strong>
                        </span>
                      </ProductItemSize>

                      <ProductItemSize>
                        <span className="icon">
                          <HeightIcon />
                        </span>
                        <span className="text">
                          <small>Height</small>
                          <strong>{productData.height}</strong>
                        </span>
                      </ProductItemSize>
                    </ProductSize>
                  </ProductItemInfo>

                  <ProductItemInfo className="product-item btn-action">
                    <strong className="h5">Order your building today</strong>
                    <ButtonToolbar>
                      <a href="tel:8666817846" aria-label="(866) 681-7846">
                        <PrimaryButton
                          icon={<PhoneIcon />}
                          text="(866) 681-7846"
                        />
                      </a>
                      <button
                        type="button"
                        onClick={() => setQuoteVisible(true)}
                        aria-label="Request A Quote"
                      >
                        <DefaultButton
                          icon={<QuoteIcon />}
                          text="Request A Quote"
                        />
                      </button>
                    </ButtonToolbar>
                  </ProductItemInfo>

                  <ProductItemInfo className="product-item cart-action">
                    <AddToCart product={productData} />
                  </ProductItemInfo>

                  {productData.applicableForRto ? (
                    <ProductItemInfo className="product-item">
                      <FinanceOption>
                        <FinanceType>
                          <FinanceItem className="active">
                            <span className="icon">
                              <TickIcon />
                            </span>
                            <span className="text">RTO Available </span>
                          </FinanceItem>
                        </FinanceType>
                        <FinanceInfo>
                          <FinancePane>
                            <div className="label">Monthly RTO Payment</div>
                            <ListPayment>
                              <ListItemPayment>
                                <strong>${rto_36_price}</strong>
                                <span>36 Months</span>
                              </ListItemPayment>
                              <ListItemPayment>
                                <strong>${rto_48_price}</strong>
                                <span>48 Months</span>
                              </ListItemPayment>
                              <ListItemPayment>
                                <strong>${rto_60_price}</strong>
                                <span>60 Months</span>
                              </ListItemPayment>
                            </ListPayment>
                          </FinancePane>
                        </FinanceInfo>
                      </FinanceOption>
                    </ProductItemInfo>
                  ) : (
                    <></>
                  )}

                  {productData.applicableForFinancing ? (
                    <ProductItemInfo className="product-item">
                      <FinanceOption>
                        <FinanceType>
                          <FinanceItem>
                            <span className="icon">
                              <TickIcon />
                            </span>
                            <span className="text">Financing Available</span>
                          </FinanceItem>
                        </FinanceType>
                      </FinanceOption>
                    </ProductItemInfo>
                  ) : (
                    <></>
                  )}
                </ProductBody>
              </Product>
            </SectionGridRight>
          </SectionGrid>
        </div>
      </Section>
      <Section pt="0" pb="90px" xpt="20px" xpb="60px" bgColor="#fff">
        <div className="container">
          <ProductFeatures>
            <ProductFeatureLeft>
              <ProductFeatureContent>
                <h2>Description</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      productData.productDescription.childMarkdownRemark.html,
                  }}
                />
              </ProductFeatureContent>
              <ProductFeatureContent>
                <h2>Technical Specs</h2>
                <TableDefault>
                  <TableRow>
                    <TableCol>
                      <span>Width</span>
                    </TableCol>
                    <TableCol>
                      <strong>{productData.width}</strong>
                    </TableCol>
                  </TableRow>
                  <TableRow>
                    <TableCol>
                      <span>Length</span>
                    </TableCol>
                    <TableCol>
                      <strong>{productData.length}</strong>
                    </TableCol>
                  </TableRow>
                  <TableRow>
                    <TableCol>
                      <span>Height</span>
                    </TableCol>
                    <TableCol>
                      <strong>{productData.height}</strong>
                    </TableCol>
                  </TableRow>
                  <TableRow>
                    <TableCol>
                      <span>Available for Finance</span>
                    </TableCol>
                    <TableCol>
                      <strong>
                        {productData.applicableForFinancing ? "Yes" : "No"}
                      </strong>
                    </TableCol>
                  </TableRow>
                  <TableRow>
                    <TableCol>
                      <span>Available for RTO</span>
                    </TableCol>
                    <TableCol>
                      <strong>
                        {productData.applicableForRto ? "Yes" : "No"}
                      </strong>
                    </TableCol>
                  </TableRow>
                  <TableRow>
                    <TableCol>
                      <span>Rust Through Warranty</span>
                    </TableCol>
                    <TableCol>
                      <strong>20 Years</strong>
                    </TableCol>
                  </TableRow>
                  {/* <TableRow>
                    <TableCol>
                      <span>Height</span>
                    </TableCol>
                    <TableCol>
                      <strong>13</strong>
                    </TableCol>
                  </TableRow> */}
                  <TableRow>
                    <TableCol>
                      <span>Workmanship Warranty</span>
                    </TableCol>
                    <TableCol>
                      <strong>30 Days</strong>
                    </TableCol>
                  </TableRow>
                  <TableRow>
                    <TableCol>
                      <span>Free Delivery and Installation</span>
                    </TableCol>
                    <TableCol>
                      <strong>Yes</strong>
                    </TableCol>
                  </TableRow>
                  <TableRow>
                    <TableCol>
                      <span>Brand</span>
                    </TableCol>
                    <TableCol>
                      <strong>Coast To Coast Carports</strong>
                    </TableCol>
                  </TableRow>
                </TableDefault>
              </ProductFeatureContent>
            </ProductFeatureLeft>
            <ProductFeatureRight>
              <ProductFeatureContent>
                <h2>Metal Building Color Options</h2>
                <ColorBoard />
                <HelpText>Available for Roof, Trim, and Sides/Ends</HelpText>
              </ProductFeatureContent>
            </ProductFeatureRight>
          </ProductFeatures>
        </div>
      </Section>
      <Section
        pt="90px"
        pb="90px"
        xpt="60px"
        xpb="60px"
        bgColor="#f7f7f7"
        bdrBottom="#dbdbdb"
      >
        <div className="container">
          <SectionTitle mb="60px">Reviews</SectionTitle>
          <ProductReviewWidget />
        </div>
      </Section>
      {/* <Section
        pt="90px"
        pb="90px"
        xpt="60px"
        xpb="60px"
        bgColor="#fff"
        bgBefore="linear-gradient(to bottom,  rgba(247,247,247,1) 0%,rgba(255,255,255,0) 100%)"
        bgBeforeWidth="100%"
        bgBeforeHeight="30%"
        className="circle-right"
      >
        <div className="container">
          <SectionTitle maxWidth="700px" mb="60px">
            Talk to Us. We can custom design steel buildings for your needs
          </SectionTitle>
          <CustomBuildingQuote
            states={data.allContentfulState.edges}
            location={location}
          />
        </div>
      </Section> */}
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <SectionTitle maxWidth="550px" mb="30px">
            You May Also Like
          </SectionTitle>
        </div>
        <LikeProductCarousel
          relatedProductsData={relatedProductsData}
          openQuoteModal={() => setQuoteVisible(true)}
        />
      </Section>
      <QuotePopupProduct
        states={data.allContentfulState.edges}
        location={location}
        isVisible={isVisibleQuote}
        onClose={() => setQuoteVisible(false)}
      />
      <CallDesignBuilding />
    </Fragment>
  )
}

export const ProductPageTemplate = ({ data, location }) => {
  const productData = data.contentfulProduct
  const breadcrumbSchema = {
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home Page",
        item: "https://www.coast-to-coastcarports.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: `${productData.productCategory.name}`,
        item: `https://www.coast-to-coastcarports.com${productData.productCategory.url}`,
      },
      {
        "@type": "ListItem",
        position: 3,
        name: `${productData.productName}`,
        item: `https://www.coast-to-coastcarports.com${productData.url}`,
      },
    ],
  }

  const productSchema = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: productData.productName,
    image: productData.productImages.file.url,
    description: productData.productDescription.productDescription,
    mpn: "MPN",
    SKU: productData.productSku,
    brand: { 
      "@type": "Brand", 
      "name": "Coast To Coast Carports" 
    },
    offers: {
      "@type": "Offer",
      url: `https://www.coast-to-coastcarports.com${productData.url}`,
      priceValidUntil: `${new Date().getFullYear()}-12-31`,
      priceCurrency: "USD",
      price: productData.price,
      itemCondition: "NewCondition",
      availability: "InStock",
      seller: {
        "@type": "Organization",
        name: "Coast To Coast Carports",
      },
      hasMerchantReturnPolicy: {
        "@type": "MerchantReturnPolicy",
        applicableCountry: "USA",
        returnPolicyCategory:
          "https://schema.org/MerchantReturnFiniteReturnWindow",
        merchantReturnDays: 60,
        returnMethod: "https://schema.org/ReturnByMail",
        returnFees: "https://schema.org/FreeReturn",
      },
      "shippingDetails": {
        "@type": "OfferShippingDetails",
        "shippingDestination": {
          "@type": "DefinedRegion",
          "addressCountry": "US"
        },
        "deliveryTime": {
          "@type": "ShippingDeliveryTime",
          "handlingTime": {
            "@type": "QuantitativeValue",
            "minValue": 1,
            "maxValue": 2,
            "unitCode": "d"
          },
          "transitTime": {
            "@type": "QuantitativeValue",
            "minValue": 3,
            "maxValue": 5,
            "unitCode": "d"
          }
        },
        "shippingRate": {
          "@type": "MonetaryAmount",
          "value": "5.00",
          "currency": "USD"
        }
      }
    },
   
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: "4.2",
      reviewCount: "30",
    },
    category: `<span>${productData.productCategory.name}</span>`,
    material: "14 GA Steel",
    width: {
      "@type": "QuantitativeValue",
      value: productData.width,
      unitcode: "FOT",
    },
    depth: {
      "@type": "QuantitativeValue",
      value: productData.length,
      unitcode: "FOT",
    },
    height: {
      "@type": "QuantitativeValue",
      value: productData.height,
      unitcode: "FOT",
    },
  }

  const schema = [breadcrumbSchema, productSchema]

  return (
    <Layout location={location} productURL={productData.url}>
      <Seo
        title={productData.metaTitle}
        description={productData.metaDescription}
        schemaMarkup={schema}
      />

      <ProductPage data={data} location={location} />
    </Layout>
  )
}

export default ProductPageTemplate

export const pageQuery = graphql`
  query ProductPageTemplateQuery($id: String!, $productCategory: String!) {
    contentfulProduct(id: { eq: $id }) {
      id
      metaTitle
      metaDescription
      productSku
      productName
      url
      productDescription {
        childMarkdownRemark {
          html
        }
        productDescription
      }
      featuredProduct
      productCategory {
        name
        url
      }
      productSubCategories {
        name
        url
      }
      productRoofStyle {
        name
      }
      productApplications {
        name
      }
      productImages {
        title
        gatsbyImageData(quality: 100)
        file {
          url
        }
      }
      width
      height
      length
      price
      applicableForFinancing
      applicableForFreeDelivery
      applicableForRto
      otherDetails {
        childMarkdownRemark {
          html
        }
      }
      installationStates {
        name
        url
      }
      productApplications {
        name
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
    allContentfulProduct(
      filter: { productCategory: { name: { eq: $productCategory } } }
    ) {
      edges {
        node {
          id
          productName
          productSku
          productName
          url
          productImages {
            title
            gatsbyImageData
          }
          width
          height
          length
          price
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
    site {
      siteMetadata {
        loanPrice {
          apr_36
          apr_48
          apr_60
        }
      }
    }
  }
`
